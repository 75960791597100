<template src="./Certificate.html">

</template>

<script>

import DesacargaOff from '@/components/Icons/DesacargaOff';

export default {
  name: "Certificate",
  components:{
    DesacargaOff,
  },
  props:{
    data: {
      type: Object,
      required: true
    }
  }
}

</script>

<style scoped lang="scss" src="./Certificate.scss"></style>
